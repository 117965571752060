<template>
  <vx-card>
    <div class="fixed-height-crm">
      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-button @click="onAdd" class="mr-4 sm:mb-0 mb-2">Add</vs-button>
        </div>
      </div>

      <ag-grid-vue
        ref="agGridTable"
        :components="cellRendererComponents"
        class="ag-theme-material w-100 grid-height-80 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="supplierData"
        :gridOptions="gridOptions"
        :floatingFilter="true"
        rowSelection="single"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        @selection-changed="onSelectionChanged"
      >
      </ag-grid-vue>
      <vs-pagination
        :total="totalPages"
        :max="5"
        v-model="currentPage" />
    </div>

  </vx-card>

</template>

<script>

import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import CellRendererSupplierCheckbox from './cell-renderers/CellRendererSupplierCheckbox.vue'
import CellRendererSupplierActions from './cell-renderers/CellRendererSupplierActions.vue'
import Vue from "vue"

export default {
  components: {
    AgGridVue,
    CellRendererSupplierCheckbox,
    CellRendererSupplierActions
  },
  data () {
    return {
      supplierData: [],
      searchQuery: '',
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
        CellRendererSupplierCheckbox,
        CellRendererSupplierActions
      }
    }
  },
  computed: {
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 10
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    }
  },
  created () {

    this.$vs.loading();
      this.$http.get('api/admin/suppliers')
      .then(response => {
        this.supplierData = response.data
      })
      .catch(error => this.$_notifyFailureByResponseData(error.response.data))
      .finally(() => this.$vs.loading.close())

  },
  mounted () {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();
  },
  beforeMount() {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { headerName: '', width: 120, sortable: false, suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererSupplierActions) },
      { headerName: 'Id', field: 'supplierId', width: 150, suppressSizeToFit: true, filter: true },
      { headerName: 'Supplier Name', field: 'supplierName', width: 300, suppressSizeToFit: true, filter: true },
      { field: 'apiTypeId', hide: true },
      { headerName: 'API Type', field: 'apiType', width: 180, suppressSizeToFit: true, filter: true },
      { field: 'productTypeId', hide: true },
      { headerName: 'Product Type', field: 'productType', width: 180, suppressSizeToFit: true, filter: true },
      { headerName: 'Is Active', field: 'isActive', sortable: true, suppressSizeToFit: true, width: 120, cellRendererFramework: Vue.extend(CellRendererSupplierCheckbox) }
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      sortable: true,
      resizable: true,
      suppressMenu: true
    }
  },

  methods: {
    onAdd () {
      this.$router.push({ name: 'admin-supplier-add'})
    },
    updateSearchQuery () {

    },
    onSelectionChanged () {

    }
  }
}
</script>

<style scoped>

  /* .fixed-height-crm {
    height: calc(var(--vh, 1vh) * 100 - 17.5rem);
    overflow-y: hidden;
    overflow-x: hidden;
    position: relative;
  } */
  .grid-height-80 {
    height: calc(var(--vh, 1vh) * 100 - 26rem) !important;
  }
</style>